.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 9999;
  opacity: 0.5;
}

.vertical-timeline-element-content {
  font-family: "Raleway";
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 5px 0px, rgba(0, 0, 0, 0.1) 0px 3px 1px -2px;
}

.vertical-timeline::before {
  width: 8px;
  left: 16.5px;
  background: #37a2cc;
}
.vertical-timeline::after {
  content: "";
  position: absolute;
  left: 8px;
  bottom: -20px;
  width: 24px;
  height: 24px;
  border-radius: 25px;
  background: #37a2cc;
}
.vertical-timeline-element--work.vertical-timeline-element:first-of-type::before {
  content: "";
  position: absolute;
  left: 8px;
  top: -50px;
  width: 24px;
  height: 24px;
  border-radius: 25px;
  background: #37a2cc;
}

.vertical-timeline-element-title {
  font-family: "Raleway";
  color: #575757;
  text-align: left;
}

/*
.vertical-timeline-element-title h5.ui.header{
  font-family: 'Raleway';
}
*/

.vertical-timeline-element-content p {
  font-size: 16px;
  color: #575757;
  text-align: left;
  margin-top: 7.5px;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline:before {
    left: 50%;
    margin-left: -4px;
  }
  .vertical-timeline::after {
    left: 50%;
    margin-left: -12px;
  }
  .vertical-timeline-element--work.vertical-timeline-element:first-of-type::before {
    left: 50%;
    margin-left: -12px;
  }
}

.slick-track .ui.tiny.circular.image {
  height: 40px !important;
  width: 40px !important;
  max-width: 40px !important;
  max-height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  margin-right: 7.5px;
}

/* .slick-track .ui.image {
  border-radius: 35px;
  height: 90vh !important;
  width: 90vh !important;
  max-width: 90vh !important;
  max-height: 90vh !important;
  min-width: 90vh !important;
  min-height: 90vh !important;
  object-fit: cover;
} */
