@import url(https://fonts.googleapis.com/css?family=Lora:400,700);
@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);
@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 9999;
  opacity: 0.5;
}

.vertical-timeline-element-content {
  font-family: "Raleway";
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 5px 0px, rgba(0, 0, 0, 0.1) 0px 3px 1px -2px;
}

.vertical-timeline::before {
  width: 8px;
  left: 16.5px;
  background: #37a2cc;
}
.vertical-timeline::after {
  content: "";
  position: absolute;
  left: 8px;
  bottom: -20px;
  width: 24px;
  height: 24px;
  border-radius: 25px;
  background: #37a2cc;
}
.vertical-timeline-element--work.vertical-timeline-element:first-of-type::before {
  content: "";
  position: absolute;
  left: 8px;
  top: -50px;
  width: 24px;
  height: 24px;
  border-radius: 25px;
  background: #37a2cc;
}

.vertical-timeline-element-title {
  font-family: "Raleway";
  color: #575757;
  text-align: left;
}

/*
.vertical-timeline-element-title h5.ui.header{
  font-family: 'Raleway';
}
*/

.vertical-timeline-element-content p {
  font-size: 16px;
  color: #575757;
  text-align: left;
  margin-top: 7.5px;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline:before {
    left: 50%;
    margin-left: -4px;
  }
  .vertical-timeline::after {
    left: 50%;
    margin-left: -12px;
  }
  .vertical-timeline-element--work.vertical-timeline-element:first-of-type::before {
    left: 50%;
    margin-left: -12px;
  }
}

.slick-track .ui.tiny.circular.image {
  height: 40px !important;
  width: 40px !important;
  max-width: 40px !important;
  max-height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  margin-right: 7.5px;
}

/* .slick-track .ui.image {
  border-radius: 35px;
  height: 90vh !important;
  width: 90vh !important;
  max-width: 90vh !important;
  max-height: 90vh !important;
  min-width: 90vh !important;
  min-height: 90vh !important;
  object-fit: cover;
} */

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
#root {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #2e2343;
}

.ui.button > .icon:not(.button) {
  opacity: 1;
}

i.icon {
  margin: 0.25em !important;
  color: #575757;
}

.ui.menu {
  margin: 0px !important;
}

.ui.item.simple.dropdown i {
  font-size: 20px;
}

.ui.card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.ui.card > .content:after,
.ui.cards > .card > .content:after {
  display: none;
}

.errors {
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px;
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  text-align: left !important;
}

.image-preview {
  padding: 10px;
  border-radius: 4px;
  background-color: #efefef;
  border: 1px solid #bbb;
}

.ui.small.modal.transition.visible.active .content {
  font-size: 1.5em !important;
}

.toastify__body {
  color: #111;
  font-size: 1.2em !important;
}

.ui.modal > .close {
  font-size: 2.25em !important;
  top: -3rem !important;
  right: -3rem !important;
}

/* File Upload */

.image-preview {
  margin-bottom: 15px !important;
}

.fileContainer {
  overflow: hidden;
  position: relative;
  background: #2e2343;
  border-radius: 50px;
  padding: 0.5em;
  color: #fff !important;
  margin-bottom: 15px !important;
}

.fileContainer.fileContainerSmall {
  width: 175px;
}

.fileContainer [type="file"] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.fileContainer [type="file"] {
  cursor: pointer;
}

i.icon.fileContainerIcon {
  color: #fff;
  margin-right: 5px !important;
}

.ui.basic.modal > .close {
  color: #fff !important;
}

.ui.menu {
  border-radius: 0px !important;
}

.public {
  font-family: Poppins !important;
}

.public-label {
  text-align: left;
  font-weight: 600;
  color: #2e2343;
  margin-bottom: 2px;
}

.public .ui.icon.left.labeled.button.right.floated {
  margin-bottom: 10px;
}

.public .ui.icon.input > i.icon {
  height: 34px;
}
.public .ui.icon.input > input ~ i.icon {
  color: #2e2343;
}

.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="url"]:focus {
  border-color: #2e2343;
}

.ui.form textarea:focus {
  border-color: #2e2343;
}

.slick-slide {
  height: 95vh;
  /* background-color: rgb(255, 255, 255);
  border-radius: 50px;
  border: 2px solid rgba(34, 36, 38, 0.15);
  padding: 2.5vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  opacity: 0; */
}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .ui.modal > .close {
    top: 0 !important;
    right: 1rem !important;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .ui.modal > .close {
    top: 0 !important;
    right: 1rem !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .ui.basic.modal > .close {
    top: 0 !important;
    right: 1rem !important;
    color: #333;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}

